<template>
  <div class="repair-password">
    <h2>修改密码</h2>
    <div class="space d-f j-b">
      <div class="item-container">
        <a-form-model
          class="form"
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          hideRequiredMark
        >
          <a-form-model-item prop="phone" label="手机号">
            <a-input v-model="form.phone" disabled />
          </a-form-model-item>
          <a-form-model-item
            prop="smsCode"
            label="验证码"
            class="sms-code"
            :wrapper-col="{ span: 12, offset: 0 }"
          >
            <a-input v-model="form.smsCode" placeholder="请输入验证码" />
            <span class="code">
              <a-button @click="sendVerifyCode" v-if="isSend">获取验证码</a-button>
              <a-button v-if="!isSend" disabled>{{ count }}s后重新获取</a-button>
            </span>
          </a-form-model-item>
          <a-form-model-item prop="newPassword" label="新密码">
            <a-input
              v-model="form.newPassword"
              placeholder="请输入新密码"
              type="password"
            />
          </a-form-model-item>
          <a-form-model-item prop="confirmNewPassword" label="确认新密码">
            <a-input
              v-model="form.confirmNewPassword"
              placeholder="请确认新密码"
              type="password"
            />
          </a-form-model-item>
          <a-form-model-item :wrapper-col="{ span: 14, offset: 6 }">
            <a-button type="primary" @click="onSubmit" class="btn"> 确认 </a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
  </div>
</template>

<script>
import { sendSmsCode, updatePassword } from "@/axios/acl.js";
export default {
  data() {
    return {
      id: "",
      form: {},
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      rules: {
        smsCode: [
          {
            required: true,
            message: "请输入验证码",
            whitespace: true,
            trigger: "blur",
          },
          {
            validator: (rule, value, callbackFn) => {
              const reg = /^\d{4}$/;
              if (!reg.test(value)) {
                callbackFn("验证码格式错误");
                return;
              }
              callbackFn();
            },
            trigger: "blur",
          },
        ],
        newPassword: [
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,
            message: "密码至少包含 数字和英文，长度6-20",
            trigger: "blur",
          },
          {
            required: true,
            message: "请输入你的密码!",
            whitespace: true,
            trigger: "blur",
          },
        ],
        confirmNewPassword: [
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,
            message: "密码至少包含 数字和英文，长度6-20",
            trigger: "blur",
          },
          {
            required: true,
            message: "请确认你的密码!",
            whitespace: true,
            trigger: "blur",
          },
        ],
      },
      isSend: true,
      timer: null,
      count: 60,
    };
  },
  methods: {
    //提交
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.form.id = this.id;
          updatePassword("acl/user/userUpdatePassword/", this.form).then((res) => {
            if (res.success) {
              this.$message.success("修改成功,请重新登录");
              this.$ls.set("userInfo", null);
              this.$ls.set("token", null);
              window.open("https://cloud.jsxniu.com/login", "_self");
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    //发送验证码
    sendVerifyCode() {
      this.$refs.ruleForm.validateField("phone", (valid) => {
        if (!valid) {
          const TIME_COUNT = 60;
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.isSend = false;
            //这里可以插入$axios调用后台接口
            sendSmsCode("web/sms/smsCode", {
              phone: this.form.phone,
              smsCodeType: 5,
            }).then((res) => {
              this.$message.info(res.message);
              if (res.success) {
                return;
              } else {
                this.$message.error(res.message);
              }
            });

            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.isSend = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
        }
      });
    },
  },
  created() {
    this.form.phone = this.$ls.get("userInfo").phone;
    this.id = this.$ls.get("userInfo").id;
  },
  destroyed() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped lang="less">
.repair-password {
  width: 936px;
  height: 710px;
  background: #fff;
  padding-left: 36px;
  padding-top: 24px;
  box-sizing: border-box;
  border-radius: 4px;
  h2 {
    font-size: 16px;
    font-weight: bold;
    line-height: 21px;
    color: #333333;
  }
  .space {
    margin-top: 48px;
    width: 640px;
    .item-container {
      width: 400px;
      height: 700px;
      .form {
        ::v-deep .ant-form-item-label {
          label {
            color: #999999;
          }
        }
        .sms-code {
          .code {
            position: absolute;
            top: -11px;
          }
        }
        .btn {
          width: 84px;
        }
      }
    }
  }
}
</style>
