import axios from 'axios'
import Vue from 'vue'

var http = axios.create({
    baseURL: "https://api.cloud.jsxniu.com/",
    timeout: 5000
});


//http拦截器
http.interceptors.request.use(config => {
  config.data = JSON.stringify(config.data)
      config.headers = {
        'Content-Type': 'application/json',
        token: Vue.ls.get('token'),
      }
  return config;
}), error => {
  console.log(error)
}

//用户信息的接口
export function getUserInfo(url) {
    return new Promise((resolve, reject) => {
        http.get(url)
            .then(response => {
                resolve(response.data)
                if(response.data.code===-4001){
                  window.open("https://cloud.jsxniu.com/login","_self");
                }
            })
            .catch(err => {
                reject(err)
            })
    })
}


//sts临牌的接口
export function getStsInfo(url) {
  return new Promise((resolve, reject) => {
      http.get(url)
          .then(response => {
              resolve(response.data)
              if(response.data.code===-4001){
                window.open("https://cloud.jsxniu.com/login","_self");
              }
          })
          .catch(err => {
              reject(err)
          })
  })
}


//更新头像的接口
export function updateAvatar(url, params = {}) {
  return new Promise((resolve, reject) => {
      http.post(url,params)
          .then(response => {
              resolve(response.data)
              if(response.data.code===-4001){
                window.open("https://cloud.jsxniu.com/login","_self");
              }
          })
          .catch(err => {
              reject(err)
          })
  })
}


//获取头像地址的接口
export function getAvatarUrl(url,params) {
  return new Promise((resolve, reject) => {
      http.get(url,{params:params})
          .then(response => {
              resolve(response.data)
              if(response.data.code===-4001){
                window.open("https://cloud.jsxniu.com/login","_self");
              }
          })
          .catch(err => {
              reject(err)
          })
  })
}



//更新个人信息的接口
export function updateInfo(url, params = {}) {
  return new Promise((resolve, reject) => {
      http.post(url,params)
          .then(response => {
              resolve(response.data)
              if(response.data.code===-4001){
                window.open("https://cloud.jsxniu.com/login","_self");
              }
          })
          .catch(err => {
              reject(err)
          })
  })
}


//获取验证码的接口
export function sendSmsCode(url, params = {}) {
  return new Promise((resolve, reject) => {
      http.post(url,params)
          .then(response => {
              resolve(response.data)
              if(response.data.code===-4001){
                window.open("https://cloud.jsxniu.com/login","_self");
              }
          })
          .catch(err => {
              reject(err)
          })
  })
}


//修改手机号的接口
export function verifyCode(url, params = {}) {
  return new Promise((resolve, reject) => {
      http.post(url,params)
          .then(response => {
              resolve(response.data)
              if(response.data.code===-4001){
                window.open("https://cloud.jsxniu.com/login","_self");
              }
          })
          .catch(err => {
              reject(err)
          })
  })
}



//解绑微信的接口
export function unbundingWx(url,params) {
  return new Promise((resolve, reject) => {
      http.get(url,{params:params})
          .then(response => {
              resolve(response.data)
              if(response.data.code===-4001){
                window.open("https://cloud.jsxniu.com/login","_self");
              }
          })
          .catch(err => {
              reject(err)
          })
  })
}


//绑定微信的接口
export function bundingWx(url,params) {
  return new Promise((resolve, reject) => {
      http.get(url,{params:params})
          .then(response => {
              resolve(response.data)
              if(response.data.code===-4001){
                window.open("https://cloud.jsxniu.com/login","_self");
              }
          })
          .catch(err => {
              reject(err)
          })
  })
}

//检查微信是否绑定的接口
export function isBindSuccess(url,params) {
  return new Promise((resolve, reject) => {
      http.get(url,{params:params})
          .then(response => {
              resolve(response.data)
              if(response.data.code===-4001){
                window.open("https://cloud.jsxniu.com/login","_self");
              }
          })
          .catch(err => {
              reject(err)
          })
  })
}


//修改密码的接口
export function updatePassword(url, params = {}) {
  return new Promise((resolve, reject) => {
      http.post(url,params)
          .then(response => {
              resolve(response.data)
              if(response.data.code===-4001){
                window.open("https://cloud.jsxniu.com/login","_self");
              }
          })
          .catch(err => {
              reject(err)
          })
  })
}
